<template>
  <div>
    <b-card>
      <b-row class="d-flex flex-row justify-content-center rounded-lg">
        <b-col>
          <b-form ref="form">
            <b-row class="rounded-lg p-2">
              <b-col cols="12" class="d-flex flex-row justify-content-between">
                <h3>Basic Information</h3>
                <b-link
                  v-if="mode === 'show' && $can('update', 'Supervisor')"
                  :to="`/company/supervisor-edit/${company_id}/${supervisor_id}`"
                  class="btn edit-btn"
                >
                  Edit <img src="@/assets/images/icons/edit-3.png" alt="img"
                /></b-link>
              </b-col>

              <b-col cols="12" md="12">
                <b-row>
                  <b-col cols="12" md="6">
                    <label for=""
                      >First Name <span class="text-danger">*</span></label
                    >
                    <b-form-group
                      invalid-feedback="First name is required"
                      :state="fnameState"
                      label-for="fname"
                    >
                      <b-form-input
                        id="fname"
                        v-model="supervisor.first_name"
                        placeholder="Enter first Name"
                        required
                        :state="fnameState"
                        class="data-text bg-light"
                        :readonly="mode === 'show'"
                        v-debounce:300="checkFormValidity"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for=""
                      >Last Name <span class="text-danger">*</span></label
                    >
                    <b-form-group
                      invalid-feedback="Last name is required"
                      :state="nameState"
                      label-for="lname"
                    >
                      <b-form-input
                        id="lname"
                        v-model="supervisor.last_name"
                        placeholder="Enter last Name"
                        required
                        :state="nameState"
                        class="data-text bg-light"
                        :readonly="mode === 'show'"
                        @keyup="checkFormValidity"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for="">
                      Contact Number <span class="text-danger">*</span></label
                    >
                    <b-form-group
                      invalid-feedback="Contact number field is required"
                      :state="contactState"
                      label-for="contact"
                    >
                      <b-form-input
                        id="contact"
                        v-model="supervisor.phone"
                        placeholder="Enter Contact Number"
                        required
                        :state="contactState"
                        class="data-text bg-light"
                        :readonly="mode === 'show'"
                        @keyup="checkFormValidity"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for="email"
                      >Email Address <span class="text-danger">*</span></label
                    >
                    <b-form-group
                      invalid-feedback="Email Address is required"
                      :state="mailState"
                    >
                      <b-form-input
                        id="email"
                        type="email"
                        v-model="supervisor.email"
                        placeholder="Enter Email Address"
                        required
                        :state="mailState"
                        class="data-text bg-light"
                        :disabled="mode === 'show'"
                        @keyup="checkFormValidity"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for="designation">
                      Designation
                      <span class="text-danger">*</span></label
                    >
                    <b-form-group
                      invalid-feedback="Designation  field is required"
                      label-for="designation"
                      :state="designationState"
                    >
                      <b-form-input
                        id="designation"
                        v-model="supervisor.supervisor.designation"
                        placeholder="Enter Designation "
                        required
                        class="data-text bg-light"
                        :readonly="mode === 'show'"
                        :state="designationState"
                        @keyup="checkFormValidity"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for="address">
                      Address
                      <span class="text-danger">*</span></label
                    >
                    <b-form-group
                      invalid-feedback="Address  field is required"
                      label-for="address"
                      :state="addressState"
                    >
                      <b-form-input
                        id="address"
                        v-model="supervisor.supervisor.address"
                        placeholder="Enter Address "
                        required
                        class="data-text bg-light"
                        :readonly="mode === 'show'"
                        :state="addressState"
                        @keyup="checkFormValidity"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col class="text-right">
                    <button
                      v-if="mode === 'edit' && $can('update', 'Supervisor')"
                      class="btn btn-warning mr-2"
                      @click.prevent="showChangePasswordModal"
                    >
                      Change Password
                    </button>
                    <button
                      @click.prevent="updateSupervisor"
                      v-if="mode === 'edit' && $can('update', 'Supervisor')"
                      class="btn edit-btn"
                    >
                      Update
                    </button>
                    <button
                      @click.prevent="registerSupervisor"
                      v-if="mode === 'create' && $can('create', 'Supervisor')"
                      class="btn edit-btn"
                    >
                      Create
                    </button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      v-model="isShowChangePasswordModal"
      title="Change Password"
      hide-footer
    >
      <p class="text-center modal-title">Rules of Strong Password (Optional)</p>
      <p class="text-center modal-sub-title">
        A - Z | a - z | 0 - 9 | Special Character | Minimum 8 Characters
      </p>
      <validation-observer ref="passwordForm">
        <b-row>
          <b-col
            v-if="
              logedInUser.roles.length > 1 &&
              logedInUser.roles[1].name === 'supervisor'
            "
            cols="12"
          >
            <b-form-group
              invalid-feedback="Old Password Is required"
              :state="oldPassword"
            >
              <label for="">
                Existing Password
                <span class="text-danger">*</span></label
              >
              <validation-provider
                #default="{ errors }"
                name="Old Password"
                vid="OldPassword"
                rules="required|min:8"
              >
                <b-form-input
                  id="a-password"
                  v-model="passwordForm.old_password"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Type your old password as condition"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              invalid-feedback="New Password Is required"
              :state="newPassword"
            >
              <label for="">
                New Password <span class="text-danger">*</span></label
              >
              <validation-provider
                #default="{ errors }"
                name="New Password"
                vid="Password"
                rules="required|password|min:8"
              >
                <b-form-input
                  id="ac-password"
                  v-model="passwordForm.password"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Type your new password as condition"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <label for="">
              Re-Type New Password
              <span class="text-danger">*</span></label
            >
            <b-form-group
              invalid-feedback="Re-Type Password Is Required"
              :state="againNewPassword"
            >
              <validation-provider
                #default="{ errors }"
                name="Re-Type New Password"
                vid="ReTypePassword"
                rules="required|confirmed:Password"
              >
                <b-form-input
                  id="ar-password"
                  v-model="passwordForm.password_confirmation"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Re-Type your new password as condition"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="text-center">
            <button
              @click="isShowChangePasswordModal = false"
              class="btn mx-1 cancel"
            >
              Cancel
            </button>
            <button @click.prevent="updatePassword" class="btn mx-1 save">
              Save
            </button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import { toast } from "@/utils/toast";
import { email, required, password } from "@validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BRow,
  BLink,
} from "bootstrap-vue";
export default {
  components: {
    BButton,
    BCard,
    BCol,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BRow,
    BLink,
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    mode: {
      type: String,
      default: "create",
    },
    supervisor: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      logedInUser: JSON.parse(getLogedInUser()),
      nameState: null,
      fnameState: null,
      contactState: null,
      mailState: null,
      designationState: null,
      addressState: null,
      company_id: null,
      supervisor_id: null,
      isShowChangePasswordModal: false,
      oldPassword: null,
      newPassword: null,
      againNewPassword: null,
      passwordForm: {
        id: this.$route.params.supervisor_id,
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      email,
      required,
      password,
    };
  },
  created() {
    if (this.mode === "show") {
      this.company_id = this.$route.params.id;
      this.supervisor_id = this.$route.params.supervisor_id;
    }
  },
  methods: {
    showChangePasswordModal() {
      this.isShowChangePasswordModal = true;
    },
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity();

      if (
        this.supervisor.first_name !== "" &&
        this.supervisor.first_name !== undefined
      ) {
        valid = true;
        this.fnameState = valid;
      } else {
        valid = false;
        this.fnameState = valid;
      }
      if (
        this.supervisor.last_name !== "" &&
        this.supervisor.last_name !== undefined
      ) {
        valid = true;
        this.nameState = valid;
      } else {
        valid = false;
        this.nameState = valid;
      }
      if (this.supervisor.phone !== "" && this.supervisor.phone !== undefined) {
        valid = true;
        this.contactState = valid;
      } else {
        valid = false;
        this.contactState = valid;
      }
      if (this.supervisor.email !== "" && this.supervisor.email !== undefined) {
        valid = true;
        this.mailState = valid;
      } else {
        valid = false;
        this.mailState = valid;
      }
      // check supervisor.email is a valid email or not
      if (valid) {
        // check email validation with regex
        const emailRegex = /\S+@\S+\.\S+/;
        valid = emailRegex.test(this.supervisor.email);
        this.mailState = valid;
      }
      if (
        this.supervisor.supervisor.designation !== "" &&
        this.supervisor.supervisor.designation !== undefined
      ) {
        valid = true;
        this.designationState = valid;
      } else {
        valid = false;
        this.designationState = valid;
      }
      if (
        this.supervisor.supervisor.address !== "" &&
        this.supervisor.supervisor.address !== undefined
      ) {
        valid = true;
        this.addressState = valid;
      } else {
        valid = false;
        this.addressState = valid;
      }
      return valid;
    },
    registerSupervisor() {
      if (!this.checkFormValidity()) {
        toast(
          "Validation Error",
          "AlertTriangleIcon",
          "danger",
          "Please fill all the supervisor fields"
        );
      } else {
        this.supervisor.user_id = this.$route.params.id;
        this.supervisor.address = this.supervisor.supervisor.address;
        this.supervisor.designation = this.supervisor.supervisor.designation;
        this.$store
          .dispatch("company/createCompanyUser", this.supervisor)
          .then((res) => {
            if (res) {
              toast(
                "Success",
                "CheckIcon",
                "success",
                "Supervisor created successfully"
              );
              this.$router.push({
                path: `/company/view/${this.$route.params.id}`,
              });
            }
          })
          .catch((error) => {
            let errorResponse = error.response?.data?.errors;
            for (const key in errorResponse) {
              if (errorResponse.hasOwnProperty(key)) {
                const errorMessages = errorResponse[key];
                // Loop through each error message in the array
                for (const errorMessage of errorMessages) {
                  toast("Error", "AlertTriangleIcon", "danger", errorMessage);
                }
              }
            }
          });
      }
    },
    updateSupervisor() {
      if (!this.checkFormValidity()) {
        toast(
          "Validation Error",
          "AlertTriangleIcon",
          "danger",
          "Please fill all the supervisor fields"
        );
      } else {
        this.supervisor.user_id = this.$route.params.id;
        this.supervisor.address = this.supervisor.supervisor.address;
        this.supervisor.designation = this.supervisor.supervisor.designation;
        this.$store
          .dispatch("company/updateCompanyUser", this.supervisor)
          .then((res) => {
            if (res) {
              toast(
                "Success",
                "CheckIcon",
                "success",
                "Supervisor updated successfully"
              );
              this.$router.push({
                path: `/company/view/${this.$route.params.id}`,
              });
            }
          })
          .catch((error) => {
            let errorResponse = error.response?.data?.errors;
            for (const key in errorResponse) {
              if (errorResponse.hasOwnProperty(key)) {
                const errorMessages = errorResponse[key];
                // Loop through each error message in the array
                for (const errorMessage of errorMessages) {
                  toast("Error", "AlertTriangleIcon", "danger", errorMessage);
                }
              }
            }
          });
      }
    },
    updatePassword() {
      this.$refs.passwordForm.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch("user/updateUserPassword", this.passwordForm)
            .then((res) => {
              if (res.status === 200) {
                this.isShowChangePasswordModal = false;
                toast(
                  "Successfully Updated",
                  "CheckCircleIcon",
                  "success",
                  "Password Updated"
                );
              } else {
                toast(
                  "Error",
                  "AlertTriangleIcon",
                  "danger",
                  "Password Not Updated"
                );
              }
            })
            .catch((error) => {
              toast(
                error.response?.data?.error || "Error",
                "AlertTriangleIcon",
                "danger",
                error.response?.data?.message || "Password Not Updated"
              );
            });
        } else {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Please fill all the required fields"
          );
        }
      });
    },
  },
};
</script>

<style scoped>
.update-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
}

.edit-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  color: white;
}
.data-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  color: #01185e;
}

.cancel {
  background: rgba(255, 79, 93, 0.1);
  color: #ff4f5d;
}

.save {
  background: #0bbe32;
  color: white !important;
}
</style>
